import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import type { DiscordTask, GetAdminQuestOutput } from '@zealy/utils';
import { DiscordJoinServerCard } from '@zealy/design-system';
import { useDiscordMetadata } from '@zealy/queries';

import { useDebouncedValue } from '#hooks/useDebouncedValue';

import { useError } from '../../../../FormError.context';

export const DiscordPreview = ({ index }: { index: number }) => {
  const t = useTranslations('quest.type.discord.errors');

  const task = useWatch({
    name: `tasks.${index}`,
  }) as DiscordTask;

  const { setValue } = useFormContext();

  const debouncedValue = useDebouncedValue(task?.settings?.inviteUrl as string, 500);

  const { setError, clearError } = useError(`tasks.${index}.settings.inviteUrl`);

  const { isLoading, data, status, isFetching, isFetched } = useDiscordMetadata(debouncedValue);

  useEffect(() => {
    if (status === 'success') {
      if (data?.isDiscordBotInGuild) clearError();
      else setError(t('bot-not-in-server'));
    } else if (status === 'error') setError(t('fetch-failed'));

    // when useQuery.enabled is false status is loading
    if (!isFetching && !isFetched && isLoading) {
      setError(t('fetch-failed'));
    }

    if (data)
      setValue(`tasks.${index}.metadata`, {
        guildId: data.id ?? '',
        name: data.name ?? '',
        imageUrl: data.imageUrl ?? data.icon ?? undefined,
        description: data.description ?? '',
      });

    return clearError;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, data, isLoading, isFetching, isFetched]);

  return (
    <DiscordJoinServerCard
      isLoading={isLoading}
      inviteLink={task?.settings?.inviteUrl}
      metadata={task['metadata']}
    />
  );
};
